import React, { Suspense, lazy } from "react";
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import { IntlProviderWrapper } from './Internationalization'

const Home = lazy(() => import("./pages/Home"));
const Movie = lazy(() => import("./pages/Movie"));
const Music = lazy(() => import("./pages/Music"));
const VideoClips = lazy(() => import("./pages/VideoClips"));
const Photography = lazy(() => import("./pages/Photography"));
const Events = lazy(() => import("./pages/Events"));
const Bio = lazy(() => import("./pages/Bio"));
const Blog = lazy(() => import("./pages/Blog"));
const Contact = lazy(() => import("./pages/Contact"));
const Commercials = lazy(() => import("./pages/Commercials"));
const NotFound = lazy(() => import("./pages/NotFound"));

var hist = createBrowserHistory();

function App() {

  return (
      <IntlProviderWrapper>
        <Router history={hist}>
          <Suspense fallback={<div></div>}>
            <div className="App">
              <Switch>
                <Route exact path="/" component={() => (<Home/>)} />
                <Route exact path="/movie" component={Movie} />
                <Route exact path="/music" component={Music} />
                <Route exact path="/videoclips" component={VideoClips} />
                <Route exact path="/photography" component={Photography} />
                <Route exact path="/events" component={Events} />
                <Route exact path="/bio" component={Bio} />
                <Route exact path="/blog" component={Blog} />
                <Route exact path="/contact" component={Contact} />
                <Route exact path="/commercials" component={Commercials} />
                <Route component={NotFound} />
              </Switch>
            </div>
          </Suspense>
        </Router>
      </IntlProviderWrapper>
  );
}

export default App;
