import React, { useState, createContext } from 'react'

// ** Intl Provider Import
import { IntlProvider } from 'react-intl'
import messages_en from "./translations/en.json";
import messages_pl from "./translations/pl.json";

const messages = {
  'en': messages_en,
  'pl': messages_pl
};

// ** Create Context
const Context = createContext()

const IntlProviderWrapper = ({ children }) => {

    let init_locale = navigator.language.split(/[-_]/)[0];
    if (localStorage.getItem("localeRJ") === null) {
      if (init_locale.includes("pl")) {
        init_locale = 'pl'
      } else {
        init_locale = 'en'
      }
      localStorage.setItem("localeRJ", init_locale)
    } else {
      init_locale = localStorage.getItem("localeRJ")
    }
  
    const [locale, setLocale] = useState(init_locale)
    const [msg, setMsg] = useState(messages[init_locale])
  
    const switchLanguage = lang => {
      setLocale(lang)
      setMsg(messages[lang])
      localStorage.setItem("localeRJ", lang)
    }

  return (
    <Context.Provider value={{ locale, switchLanguage }}>
        <IntlProvider key={locale} locale={locale} messages={msg} defaultLocale="en">
            {children}
        </IntlProvider>
    </Context.Provider>
  )
}

export { IntlProviderWrapper, Context as IntlContext }